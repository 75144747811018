<template>

  <div id="form-inscripciones-add">
    <div class="card card-custom border-round-10 card-shadow border-0 mb-3">
      <div class="card-body d-flex flex-column mh-700">

        <div class="row mb-3">
          <div class="col-12">
            <div class="d-flex">
              <div class="flex-shrink-0">
                <img 
                  class="inscripciones-add__user-img" 
                  :src="handleUserImage(usuario.imagen_perfil)"
                />
              </div>
              <div class="flex-grow-1 d-flex align-items-center ms-3">
                <h6 class="font-main-bold mb-0">
                  {{ usuario.primer_nombre | capitalize }} 
                  {{ usuario.apellido_paterno | capitalize }}<br>
                  <span class="text-secondary">
                    {{ usuario.cargo[0] != undefined ? usuario.cargo[0].nombre_cargo:''}}
                  </span>
                </h6>
              </div>
            </div>            
          </div>
        </div>

        <div class="row flex-grow-1">
          <div class="col-12 col-lg-6 d-flex flex-column">
            <h6 
              class="font-title-bold"
              :class=" !solicita_transporte ? 'color-main' : '' "
            >
              Selecciona tu información
            </h6>
            <div 
              class="card flex-grow-1"
              :class=" !solicita_transporte ? 'border-primary' : '' "
              >
              <div class="card-body">
                <div class="mb-3">
                  <label class="text-secondary">Fecha en la que deseas inscribir el viaje</label>
                  <v-select 
                    class="selvue-custom"
                    :placeholder="'Selecciona la fecha'" 
                    :options="planes_list" 
                    label="nombre_planificacion"
                    v-model="periodo"
                    @input="selectPeriodo(periodo)" 
                    :clearable="false" 
                    :searchable="false"
                  >
                    <span slot="no-options"></span>
                  </v-select>
                </div>
                <h6 class="font-main border-bottom mb-2 pb-1">Información del turno</h6>
                <div class="row mb-3">
                  <div class="col-12 col-lg-6">
                    <label class="text-secondary">Turno</label>
                    <v-select 
                      class="selvue-custom"
                      :placeholder="'Selecciona un turno'" 
                      :options="turnos_list" 
                      label="nombre_turno"
                      v-model="turno" 
                      @input="selectTurno(turno.nombre_turno, turno.id_turno)" 
                      :clearable="false"
                      :searchable="false"
                    >
                      <span slot="no-options"></span>
                    </v-select>
                  </div>
                  <div class="col-12 col-lg-6">
                    <label class="text-secondary">Team</label>
                    <v-select 
                      class="selvue-custom"
                      :placeholder="'Selecciona un team'" 
                      :options="teams_list" 
                      label="nombre_team"
                      v-model="team"
                      @input="selectTeam(team.nombre_team, team.id_team)" 
                      :clearable="false"
                      :searchable="false"
                    >
                      <span slot="no-options"></span>
                    </v-select>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div 
            v-if="solicita_transporte"
            class="col-12 col-lg-6 d-flex flex-column">
            <h6 
              class="font-title-bold"
              :class=" solicita_transporte ? 'color-main' : '' "
            >
            Solicita tu transporte
          </h6>
            <div 
              class="card flex-grow-1"
              :class=" solicita_transporte ? 'border-primary' : '' "
            >
              <div class="card-body">
                <h6 class="font-main border-bottom mb-2 pb-1">
                  <font-awesome-icon 
                    v-if="transporte === 'Aéreo' " 
                    :icon="['fa-solid', 'plane']" 
                    class="color-green"
                  />
                  <font-awesome-icon 
                    v-if="transporte === 'Terrestre' " 
                    :icon="['fa-solid', 'bus-simple']" 
                    class="color-green"
                  />
                  Viaje de ida
                </h6>   
                <div>
                  <label class="text-secondary">Selecciona un tramo</label>
                  <v-select 
                    class="selvue-custom"
                    :placeholder="'Tramos disponibles'" 
                    :options="tramosApp_list" 
                    label="tramo"
                    :disabled="disable_right_side"
                    v-model="tramo" 
                    @input="selectTramo(tramo)"
                    :clearable="false"
                    :searchable="false"
                  >
                    <span slot="no-options"></span>
                  </v-select>
                </div>
                <div 
                  v-if="hora_tramo_seleccionado !== ''"
                  class="mt-3">
                  <div class="alert alert-light border d-flex justify-content-between align-items-center" style="height: 60px;">
                    <span class="text-secondary">
                      <i class="fa-solid fa-circle-arrow-right color-green"></i>
                      Salida: 
                      <i class="fa-regular fa-clock"></i>
                      {{hora_tramo_seleccionado+' hrs.'}}
                    </span>
                    <span>
                      <div 
                        v-if="show_ida_vuelta_radio"
                        class="form-check form-switch form-switch-custom form-switch-custom-reverse">
                        <input 
                          class="form-check-input"
                          type="checkbox" 
                          id="switch-ida-vuelta"
                          v-model="ida_vuelta_radio" 
                          @input="checkRadioVuelta()"
                        >
                        <label
                          class="form-check-label link-cursor" 
                          for="switch-ida-vuelta"
                        >
                          <i class="fa-solid fa-arrow-right-arrow-left pe-1"></i>
                          Ida y vuelta
                        </label>
                      </div>
                    </span>
                  </div>
                </div>

                <div 
                  v-if="tramo !== '' "
                  class="accordion accordion-custom mt-3">
                  <div class="accordion-item">
                    <h2 class="accordion-header">
                      <button 
                        class="accordion-button collapsed" 
                        type="button"
                        data-bs-toggle="collapse" 
                        data-bs-target="#collapse-asiento-ida" 
                        aria-expanded="false"
                      >
                      <span class="d-flex justify-content-between w-100">
                        <span>
                          <i class="fa-solid fa-couch color-secondary pe-2"></i>
                          Elige un asiento
                        </span>
                          <span 
                            v-show="selected_seat.going !== ''"
                            class="badge bg-success rounded-pill me-2">
                            {{ selected_seat.going }}
                          </span>
                      </span>
                      </button>
                    </h2>
                    <div 
                      id="collapse-asiento-ida" 
                      class="accordion-collapse collapse" 
                    >
                      <div class="accordion-body">
                        <div class="row">
                          <div class="col-12 form-nums">
                            <button 
                              v-for="asiento in asientos" 
                              :key="asiento.id_asiento" 
                              class="form-nums-item"
                              :class="statusAsiento(asiento)"
                              @click="selectAsiento(asiento, 'ida')">
                              {{ asiento.nombre_asiento }}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div 
                  v-if="ida_vuelta_radio"
                  class="row mt-3">
                  <div class="col-12">
                    <h6  class="font-main border-bottom mb-2 pb-1">
                      <font-awesome-icon 
                        v-if="transporte === 'Aéreo' " 
                        :icon="['fa-solid', 'plane']" 
                        class="color-red"
                      />
                      <font-awesome-icon 
                        v-if="transporte === 'Terrestre' " 
                        :icon="['fa-solid', 'bus-simple']" 
                        class="color-red"
                      />
                      Viaje de vuelta
                    </h6>  

                    <div>
                      <label class="text-secondary">Selecciona un tramo</label>
                      <v-select 
                        class="selvue-custom"
                        :placeholder="'Tramos disponibles'" 
                        :options="tramosAppVuelta_list" 
                        label="tramo"
                        v-model="tramoVuelta" 
                        @input="selectTramoVuelta(tramoVuelta)" 
                        :clearable="false"
                        :searchable="false"
                      >
                        <span slot="no-options"></span>
                      </v-select>  
                      <div 
                        v-if="hora_tramovuelta_seleccionado !== ''"
                        class="mb-3">
                        <div class="alert alert-light border mt-3">
                          <i class="fa-solid fa-circle-arrow-left color-red"></i>
                          Salida: 
                          <i class="fa-regular fa-clock"></i>
                          {{hora_tramovuelta_seleccionado + ' hrs.'}}
                        </div>
                      </div>
                    </div>

                    <div 
                      v-if="tramoVuelta !== '' "
                      class="accordion accordion-custom mt-3">
                      <div class="accordion-item">
                        <h2 class="accordion-header">
                          <button 
                            class="accordion-button collapsed" 
                            type="button"
                            data-bs-toggle="collapse" 
                            data-bs-target="#collapse-asiento-vuelta" 
                            aria-expanded="false" 
                          >
                          <span class="d-flex justify-content-between w-100">
                            <span>
                              <i class="fa-solid fa-couch color-secondary pe-2"></i>
                              Elige un asiento
                            </span>
                              <span 
                                v-show="selected_seat.coming !== ''"
                                class="badge bg-danger rounded-pill me-2">
                                {{ selected_seat.coming }}
                              </span>
                          </span>
                          </button>
                        </h2>
                        <div 
                          id="collapse-asiento-vuelta" 
                          class="accordion-collapse collapse" 
                        >
                          <div class="accordion-body">
                            <div class="row">
                              <div class="col-12 form-nums">
                                <button 
                                  v-for="(asiento, av) in asientosVuelta" 
                                  :key="av" 
                                  class="form-nums-item"
                                  :class="statusAsiento(asiento)"
                                  @click="selectAsiento(asiento, 'vuelta')"
                                >
                                  {{ asiento.nombre_asiento }}
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> 
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-12">
            <div class="d-flex align-items-center justify-content-end pt-2 mt-4 border-top border-2">
              <button 
                class="btn btn-custom-color-white border border-round-50 mw-100 me-2" 
                @click="cancel()">
                Volver
              </button>
              <button 
                class="btn btn-custom-color-blue border-round-50 mw-100" 
                :disabled="validateButtonSend()"
                @click="addOrEditInscripcion()"
              >
                {{ nameAction() }}
              </button>
            </div>
          </div>
        </div>

        <Question 
          v-if="open_question_modal_edit" 
          :msg="question_modal_msg_edit" 
          :hideCancel="false"
          @cancel="cancelQuestionEdit" 
          @accept="acceptQuestionEdit" 
        />
        <Question 
          v-if="open_question_modal" 
          :msg="question_modal_msg" 
          :hideCancel="false" 
          @cancel="cancelQuestion"
          @accept="acceptQuestion"
        />
        <Status 
          v-if="open_modal_status" 
          :msg="modal_status_msg" 
          :status="modal_status" 
          @close="acceptStatus"
        />
        <Spinner v-if="show_spinner"/>
      </div>
    </div>
  </div>

</template>

<script>
import { mapActions, mapState } from "vuex";
import Question from "../Modales/Question.vue";
import Status from "../Modales/Status.vue";
import Spinner from "../Spinner.vue";
export default {
  components: {
    Question,
    Status,
    Spinner,
  },

  props: {
    showComponent: {
      type: String,
    },
  },

  data() {
    return {
      show_ida_vuelta_radio: false,
      ida_vuelta_radio: false,
      is_request_edit: false,
      disable_right_side: true,
      selected_seat: {
        going: '',
        coming:''
      },
      show_spinner: false,
      user: this.$ls.get("user"),
      user_default_img: require("@/assets/img/user_default_img.svg"),
      usuario: {
        apellido_materno: '',
        apellido_paterno: '',
        cargo: [{
          id_cargo: "",
          nombre_cargo: ""
        }],
        correo: '',
        dv_usuario:'',
        gerencia: [{
          bussiness_unit: "",
          id_gerencia: "",
          nombre_gerencia: "",
          unit_number: "",
        }],
        id_usuario: "",
        imagen_perfil: '',
        primer_nombre: '',
        rut_usuario: '',
        segundo_nombre: '',
        telefono_fijo: '',
        telefono_movil: ''
      },
      periodo: "",
      turno: "",
      team: "",
      transporte: "Terrestre",
      id_tipoTransporte: "1",
      tramo: "",
      tipo: "",
      tramoVuelta: "",

      id_planificacion: "",
      fecha_viaje: "",
      id_tramo: "",
      id_tramoVuelta: "",

      hora_tramo_seleccionado:'',
      hora_tramovuelta_seleccionado:'',
      
      id_tipo: "",
      asientos: [],

      solicita_transporte: false,
      vuelta: 0,

      asientosVuelta: [],

      open_question_modal: false,
      question_modal_msg: "",
      open_modal_status: false,
      modal_status_msg: "",
      modal_status: true,

      question_modal_msg_edit: false,
      open_question_modal_edit: "",

      tramosAsientos: [],

      asientoIda: {},
      AsientoVuelta: {},

      id_turno: "",
      id_team: "",
      id_user: "",

      goback_totramos: false
    };
  },

  computed: {
    ...mapState("transporteModule", [
      "planes_list",
      "turnos_list",
      "teams_list",
      "tramosApp_list",
      "idaVuelta_list",
      "tramosAppVuelta_list",
      "solicitudes_list",
      "solicitudesArr",
    ]),

    left_side_inputs() {
      return {
        fecha_viaje: this.fecha_viaje,
        turno: this.turno,
        team: this.team
      }
    }
  },

  watch: {
    left_side_inputs(val) {
      if (val.fecha_viaje && val.turno && val.team) {
        this.solicita_transporte = true;
        this.disable_right_side = false;
      } else {
        this.solicita_transporte = false;
        this.disable_right_side = true;
      }
    },
  },

  async created() {
    this.show_spinner = true;
    if (this.$route.params.id != undefined && this.$route.params.id != "") { 
      if (this.$route.params.action != undefined && this.$route.params.action != '') {
        if (this.$route.params.action == 'add') { 
          this.id_user = this.$route.params.id;
          let sol_found = this.solicitudesArr.find(solicitud => solicitud.usuario.id_usuario == this.id_user)
          if (sol_found != undefined) {
            this.usuario = sol_found.usuario
          }
        }
        if(this.$route.params.action == 'edit'){
          if(this.$route.params.from != undefined && this.$route.params.from == 'tramos'){
            this.goback_totramos = true;
          }else{
            this.goback_totramos = false;
          }
          this.is_request_edit = true;
          this.disable_right_side = false;
          let solicitud = {
            idEmpresa: this.$ls.get("user").empresa[0].id_empresa,
            idUsuario: this.$route.params.id,
          };
          await this.getFindSolicitudes(solicitud);
         if(this.solicitudes_list != undefined)
          this.llenar(this.solicitudes_list);
        }
      }else {
        this.is_request_edit = true;
          this.disable_right_side = false;
          let solicitud = {
            idEmpresa: this.$ls.get("user").empresa[0].id_empresa,
            idUsuario: this.$route.params.id,
          };
          await this.getFindSolicitudes(solicitud);
         if(this.solicitudes_list != undefined)
          this.llenar(this.solicitudes_list);
      }
    }
    // planificaciones
    let empresa = {
      idEmpresa: this.$ls.get("user").empresa[0].id_empresa,
    };
    await this.getPlanificacionesApp(empresa);
    await this.getTurnos(empresa);
    await this.getTeams(empresa);
    this.show_spinner = false;
  },

  async mounted() {
    this.periodo = "";
    await this.resetPlanificacionesList();
    this.clearFields();
  },

  methods: {
    ...mapActions("transporteModule", [
      "getPlanificacionesApp",
      "getTurnos",
      "getTeams",
      "getTramosApp",
      "getIdaVuelta",
      "getTramosAppVuelta",
      "getFindSolicitudes",
      "addSolicitud",
      'addSolicitudesPaginated',
      'editSolicitudesPaginated',
      'resetTramosList',
      'resetPlanificacionesList',
    ]),
    checkRadioVuelta(){
      if(this.ida_vuelta_radio){
        this.tipo = "Ida";
      }else{
        this.tipo = "Ida y vuelta";
      }
    },
    async clearFields() {
      await this.resetTramosList();
      this.tramo = "";
      this.tramoVuelta = "";
      this.tipo = "";
      this.show_ida_vuelta_radio = false;
      this.ida_vuelta_radio = false;
      this.disable_right_side = false;
      this.asientos = [];
      this.asientosVuelta = [];
      this.hora_tramo_seleccionado = '';
      this.hora_tramovuelta_seleccionado = '';
    },

    nameAction() {
      if (this.is_request_edit) return "Guardar";
      else return "Añadir";
    },
    validateButtonSend() {
      if (this.tramo == "" || this.selected_seat.going == "") return true;
      else return false;
    },
    async llenar(data) {
      // 1- periodo
      if (data.length > 0) {
        this.periodo = this.obtenerFecha(data[0].transporte_tramo.fecha_viaje);
        this.fecha_viaje = data[0].transporte_tramo.fecha_viaje;
        // turno
        this.turno = data[0].usuario.turno[0].nombre_turno;
        this.id_turno = data[0].usuario.turno[0].id_turno;
        // team
        this.team = data[0].usuario.team.nombre_team;
        this.id_team = data[0].usuario.team.id_team;
        this.id_tipoTransporte = data[0].transporte_tramo.transporte_lista_destino.transporte_tipo_medio.transporte_tipo.id_tipo;
        this.solicita_transporte = true;
        // 5- tramo
        this.tramo = data[0].tramo;
        this.id_tramo = data[0].transporte_tramo.id_tramo;
        this.id_planificacion = data[0].transporte_tramo.transporte_planificacion.id_planificacion;
        this.usuario = data[0].usuario;
         //busqueda de asientos
        let asiento_ida = {
          idEmpresa: this.$ls.get("user").empresa[0].id_empresa,
          idPlanificacion:this.id_planificacion,
          fechaViaje: this.fecha_viaje,
          idTipoTransporte: this.id_tipoTransporte,
          isPrincipal: "1",
          idTramoPadre: "0",
        };
        await this.getTramosApp(asiento_ida);
        let tramo_index = this.tramosApp_list.findIndex(tramo => tramo.id_tramo == this.id_tramo);
        if (tramo_index != -1) {
          this.asientos = this.tramosApp_list[tramo_index].asientos;
          this.hora_tramo_seleccionado = this.tramosApp_list[tramo_index].hora_viaje
          let index_seat = this.asientos.findIndex(seat => (
            seat.nombre_asiento === data[0].transporte_asiento.nombre_asiento
          ))
          this.asientos.forEach(seat => {
            seat.selected = false;
          })
          if (index_seat != -1) {
            this.asientos[index_seat].disponible = false;
            this.asientos[index_seat].selected = true;
            this.selected_seat.going = this.asientos[index_seat].nombre_asiento
            this.selectAsiento(this.asientos[index_seat], 'ida');
          }
        }
         
        if (data.length > 1) {
          this.show_ida_vuelta_radio = true;
          this.ida_vuelta_radio = true;
          this.vuelta = 2;
          this.tipo = "Ida y vuelta";
          this.id_tramoVuelta = data[1].transporte_tramo.id_tramo;
          let asiento_vuelta = {
            idEmpresa: this.$ls.get("user").empresa[0].id_empresa,
            idPlanificacion: this.id_planificacion,
            fechaViaje: this.fecha_viaje,
            idTipoTransporte: this.id_tipoTransporte,
            idTramoIda: this.id_tramo,
          };
          await this.getTramosAppVuelta(asiento_vuelta);
           
          let tramo_vuelta_index = this.tramosAppVuelta_list.findIndex(tramo => tramo.id_tramo == data[1].transporte_tramo.id_tramo);
          if (tramo_vuelta_index != -1) {
            this.asientosVuelta = this.tramosAppVuelta_list[tramo_vuelta_index].asientos;
            this.hora_tramovuelta_seleccionado = this.tramosAppVuelta_list[tramo_vuelta_index].hora_viaje
            this.asientosVuelta.forEach(seat => {
              seat.selected = false;
            })
            this.tramoVuelta = this.tramosAppVuelta_list[tramo_vuelta_index];
            let index_seat_vuelta = this.asientosVuelta.findIndex(seat => (
             seat.nombre_asiento === data[1].transporte_asiento.nombre_asiento
            ))
            if (index_seat_vuelta != -1) {
              this.asientosVuelta[index_seat_vuelta].disponible = false;
              this.asientosVuelta[index_seat_vuelta].selected = true;
              this.selectAsiento( this.asientos[index_seat_vuelta], 'vuelta', true)
              this.selected_seat.coming = this.asientos[index_seat_vuelta].nombre_asiento
            }
          }
        }
      }
    },

    handleUserImage(imagen_perfil) {
      if (
        imagen_perfil !== null &&
        imagen_perfil !== "" &&
        imagen_perfil !== undefined
      ) {
        return imagen_perfil;
      } else {
        return this.user_default_img;
      }
    },

    // periodo
    async selectPeriodo(plan) {
      this.clearFields()
      this.periodo = plan.nombre_planificacion; /* this.obtenerFecha(plan.fecha_viaje) */
      this.id_planificacion = plan.id_planificacion;
      this.fecha_viaje = plan.fecha_viaje;
      this.solicita_transporte = false;
      this.tramoVuelta = "";
      this.tramosAsientos = [];
      this.selected_seat.going = '';
      this.selected_seat.coming = '';
      let asiento = {
        idEmpresa: this.$ls.get("user").empresa[0].id_empresa,
        idPlanificacion: this.id_planificacion,
        fechaViaje: this.fecha_viaje,
        idTipoTransporte: this.id_tipoTransporte,
        isPrincipal: "1",
        idTramoPadre: "0",
      };
      await this.getTramosApp(asiento);
    },
    // turno
    selectTurno(status, id) {
      this.turno = status;
      this.id_turno = id;
    },
    // Team
    selectTeam(status, id) {
      this.team = status;
      this.id_team = id;
    },

    // Tramo ida
    async selectTramo(tramo) {
      this.selected_seat.going = '';
      this.selected_seat.coming = '';
      this.tramo = tramo.tramo;
      this.id_tramo = tramo.id_tramo;
      this.asientos = tramo.asientos;
      this.tramosAsientos = [];
      this.hora_tramo_seleccionado = tramo.hora_viaje;
      let data = {
        idEmpresa: this.$ls.get("user").empresa[0].id_empresa,
        idPlanificacion: this.id_planificacion,
        fechaViaje: this.fecha_viaje,
        idTipoTransporte: this.id_tipoTransporte,
        idTramoIda: this.id_tramo,
      };
      await this.getTramosAppVuelta(data);
      if (this.tramosAppVuelta_list.length > 0) {
        this.show_ida_vuelta_radio = true;
      } else {
        this.show_ida_vuelta_radio = false;
      }
    },
    // ida vuelta
    async selectTipoViaje(tipo) {
      this.id_tipo = tipo.id_ida_vuelta;
      if (tipo.id_ida_vuelta == "1") {
        this.tipo = "Ida";
        this.vuelta = 0;
      }
      if (tipo.id_ida_vuelta == "2") {
        this.tipo = "Ida y vuelta";
        if (this.tramosAppVuelta_list.length == 0) {
          this.vuelta = 1;
          this.tramoVuelta = "No hay tramos";
        }
        // selecciono vuelta y hay datos
        if (this.tramosAppVuelta_list.length != 0) {
          this.vuelta = 2;
          
        }
      }
    },

    // asientos ida
    selectAsiento(asiento, tipo, action) {
      this.usuario.nombre_asiento = "";
      if (tipo == "ida") {
        this.asientos.forEach(seat => {
          if (seat.disponible) {
            seat.selected = false;
          } else {
            if (seat.selected) {
              seat.selected = false;
              seat.disponible = true;
            }
          }
        });
        const index = this.asientos.findIndex((t) => t.id_asiento === asiento.id_asiento);
        if (index != -1) {
          if (!this.asientos[index].disponible) return;
          this.asientos[index].selected = true;

          //FF: Esta variable producia el error de los asientos al volver a la lista
          //this.asientos[index].disponible = false;
          this.selected_seat.going = this.asientos[index].nombre_asiento;
        }
        this.asientoIda = {
          id_tramo: this.id_tramo,
          id_asiento: asiento.id_asiento,
          id_tramo_conexion: null,
          id_asiento_conexion: null,
          id_ida_vuelta: "1",
        };
        if (this.tramosAsientos.length < 1) {
          this.tramosAsientos.push(this.asientoIda);
        } else {
          let index = this.tramosAsientos.findIndex(
            (t) => t.id_ida_vuelta === this.asientoIda.id_ida_vuelta
          );
          if (index != -1) {
            this.tramosAsientos[index] = this.asientoIda;
          }
          if (index == -1) {
            this.tramosAsientos.push(this.asientoIda);
          }
        }
      }
      if (tipo == "vuelta") {
        this.asientosVuelta.forEach(seat_back => {
          if (seat_back.disponible) {
            seat_back.selected = false;
          } else {
            if (seat_back.selected && !action) {
              seat_back.selected = false;
              seat_back.disponible = true;
            }
          }
        });
        let index_vuelta = this.asientosVuelta.findIndex(
          (t) => t.id_asiento === asiento.id_asiento
        );
        if (index_vuelta != -1) {
          if (!this.asientosVuelta[index_vuelta].disponible) return;
          this.asientosVuelta[index_vuelta].selected = true;
          this.asientosVuelta[index_vuelta].disponible = false;
          this.selected_seat.coming = this.asientosVuelta[index_vuelta].nombre_asiento;
        } 
        this.AsientoVuelta = {
          id_tramo: this.id_tramoVuelta,
          id_asiento: asiento.id_asiento,
          id_tramo_conexion: null,
          id_asiento_conexion: null,
          id_ida_vuelta: "2",
        };
        if (this.tramosAsientos.length == 0) {
          this.tramosAsientos.push(this.AsientoVuelta);
        }
        if (this.tramosAsientos.length != 0) {
          let index = this.tramosAsientos.findIndex(
            (t) => t.id_ida_vuelta === this.AsientoVuelta.id_ida_vuelta
          );
          if (index != -1) {
            this.tramosAsientos[index] = this.AsientoVuelta;
          }
          if (index == -1) {
            this.tramosAsientos.push(this.AsientoVuelta);
          }
        }
      }
    },
    statusAsiento(asiento) {
      if (asiento.selected) {
        return "selected";
      }
      if (!asiento.disponible) {
        return "inactive";
      }
    },
    selectTramoVuelta(tramo_) {
      this.id_tramoVuelta = tramo_.id_tramo;
      this.asientosVuelta = tramo_.asientos;
      let tramo_vuelta_index = this.tramosAppVuelta_list.findIndex(tramo => tramo.id_tramo == tramo_.id_tramo);
          if (tramo_vuelta_index != -1) {
            this.hora_tramovuelta_seleccionado = this.tramosAppVuelta_list[tramo_vuelta_index].hora_viaje
          }
    },
    async cancel() {
      this.periodo = "";
      await this.resetPlanificacionesList();
      this.clearFields();
      this.asientos.forEach(seat => {
        seat.selected = false;
      })
      this.tramosAsientos = []
      this.usuario.nombre_asiento = '';

      if(this.goback_totramos){
        this.$router.push({ name: "transporte-en_curso" });
      }else{
        this.$router.push({ path: "/transporte/inscripciones/list" });
      }
    },
    addOrEditInscripcion() {
      if (!this.is_request_edit) {
        if (this.comprobar()) {
          this.acceptQuestion();
        }
      } else {
        if (this.comprobar()) {
          this.acceptQuestionEdit();
        }
      }
    },
    async acceptQuestion() {
      this.show_spinner = true;
      let data = {
        idEmpresa: this.$ls.get("user").id_empresa_fk,
        idUsuario: this.usuario.id_usuario,
        tramos: this.tramosAsientos,
        idTurno: this.id_turno,
        idTeam: this.id_team,
      };
      try {
        await this.addSolicitudesPaginated(data);
        this.toastSolicitudCreateSuccess();
        this.show_spinner = false;
        this.$router.push({ name: "transporte-inscripciones-list" });
      } catch (error) {
        this.toastSolicitudCreateError();
        this.show_spinner = false;
        throw error;
      }
    },
    cancelQuestion() {
      this.open_question_modal = false;
    },
    acceptStatus() {
      this.open_modal_status = false;
      this.toastSolicitudCreateSuccess();
      this.$router.push({ name: "transporte-inscripciones-list" });
    },
    comprobar() {
      return (
        this.tramosAsientos.length != 0 &&
        this.id_turno != "" &&
        this.id_team != ""
      );
    },
    cancelQuestionEdit() {
      this.open_question_modal_edit = false;
    },
    async acceptQuestionEdit() {
      this.show_spinner = true;
      try {
        let soli = [];
        this.solicitudes_list.forEach((s) => {
          soli.push(s.id_solicitud);
        });
        let data = {
          idEmpresa: this.$ls.get("user").id_empresa_fk,
          idUsuario: this.usuario.id_usuario,
          tramos: this.tramosAsientos,
          idTurno: this.id_turno,
          idTeam: this.id_team,
          idCreador: "1",
          idSolicitud: soli,
        };
        await this.editSolicitudesPaginated(data)
        this.toastSolicitudEditSuccess();
        this.show_spinner = false;
        this.$router.push({
          name: "transporte-inscripciones-list",
        });
      } catch (error) {
        this.toastSolicitudEditError();
        this.show_spinner = false;
        throw error;
      }
    },

    obtenerFecha(fecha) {
      let semana = [
        "Domingo",
        "Lunes",
        "Martes",
        "Miércoles",
        "Jueves",
        "Viernes",
        "Sábado",
      ];
      let meses = [
        "Enero",
        "Febrero",
        "Marzo",
        "Abril",
        "Mayo",
        "Junio",
        "Julio",
        "Agosto",
        "Septiembre",
        "Octubre",
        "Noviembre",
        "Diciembre",
      ];
      fecha = new Date(fecha + "T00:00:00");
      let diaSemana = fecha.getDay();
      let dia = fecha.getDate();
      let mes = fecha.getMonth();
      return `${semana[diaSemana]} ${dia} de ${meses[mes]}`;
    },

    toastSolicitudCreateSuccess() {
      this.$toast.open({
        message: "Tu solicitud ha sido realizada correctamente.",
        type: "success",
        duration: 5000,
        position: "top-right",
      });
    },
    toastSolicitudCreateError() {
      this.$toast.open({
        message: "Ocurrió un problema al agregar la solicitud. Por favor intenta nuevamente.",
        type: "error",
        duration: 5000,
        position: "top-right",
      });
    },
    toastSolicitudEditSuccess() {
      this.$toast.open({
        message: "Tu solicitud ha sido editada correctamente.",
        type: "success",
        duration: 5000,
        position: "top-right",
      });
    },
    toastSolicitudEditError() {
      this.$toast.open({
        message: "Error al editar la solicitud. Por favor intenta nuevamente.",
        type: "error",
        duration: 5000,
        position: "top-right",
      }); 
    }
  },
  //FF
  filters: {
    capitalize: function (value) {
      if (!value) return ''
      value = value.toString().toLowerCase();
      return value.charAt(0).toUpperCase() + value.slice(1)
    }
  }
};
</script>
